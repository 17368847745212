import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IconGoogleCloud = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g
        id="Groupe_6135"
        data-name="Groupe 6135"
        transform="translate(-150.41 -288.445)"
      >
        <path
          id="Tracé_16565"
          data-name="Tracé 16565"
          d="M210.785,325.125a10.33,10.33,0,0,1-8.315-.758l-.052-.031a7.956,7.956,0,0,1-3.12-2.67c-.471-.817-2.258-1.749-.606-2.76,1.16-.71,2.577-2.192,4.187,0a5.593,5.593,0,0,0,7.248,1.842c2.345-1.1,3.335-3.408,2.947-6.84-6.393,3.269-11.993,1.818-15.04-3.892A11,11,0,0,1,202.4,295.4a8.126,8.126,0,0,1,6.143-1.157c2.514.682,4.821,2.088,7.629.741,1.247-.6,1.756.478,1.745,1.808-.055,6.3.152,12.613-.1,18.909-.177,4.367-2.244,7.764-6.667,9.3-.08.028-.208.069-.371.121m-2.968-13.634c3.352-.021,5.9-2.926,5.808-6.632-.087-3.553-2.632-6.31-5.853-6.338a6.223,6.223,0,0,0-6.119,6.667,6.078,6.078,0,0,0,6.164,6.3"
          transform="translate(114.259 13.753)"
          fill="#fff"
        />
        <path
          id="Tracé_16566"
          data-name="Tracé 16566"
          d="M152.566,305.851c2.223-8.287,7.789-13.312,15.7-16.086a30.175,30.175,0,0,1,5.78-1.319h1.43a34.92,34.92,0,0,1,5.132.987,21.483,21.483,0,0,1,9.984,5.305c-3.719,4.7-2.656,2.784-6.379,7.487-6.452-3.938-12.661-4.471-18.451.918a21.4,21.4,0,0,0-13.194,2.708"
          transform="translate(5.31)"
          fill="#e94738"
        />
        <path
          id="Tracé_16567"
          data-name="Tracé 16567"
          d="M167.557,297.4l6.964-7.141c3.162,2.175,5,5.333,6.383,8.772a10.679,10.679,0,0,0,2.746,3.979c5.427,5.056,6.919,11.245,4.672,18.254-1.946,6.071-8.266,10.791-14.992,11.068-4.741.2-9.492-.021-14.24-.055l.073-10.164c4.08-.031,8.159-.045,12.239-.107,3.99-.055,6.528-1.894,7.124-5.094.651-3.515-.938-6.092-4.734-7.615-.779-.312-1.777-.291-1.7-1.558.242-4.2-1.849-7.4-4.533-10.337"
          transform="translate(21.38 4.475)"
          fill="#527cbe"
        />
        <path
          id="Tracé_16568"
          data-name="Tracé 16568"
          d="M198.318,305.909a36.219,36.219,0,0,0,4.945-.042c2.552-.357,2.954.98,2.9,3.023-.194,6.493-2.964,11.425-9.011,14.05a16.9,16.9,0,1,1-12.931-31.209c5.323-1.887,10.749-1.631,15.556,1.87.693.506,2.76.925,1.174,2.442-.956.918-1.437,2.989-3.844,1.413a12.189,12.189,0,0,0-14.064,19.909,12.362,12.362,0,0,0,14.656-.156,10.737,10.737,0,0,0,2.286-2.6c.661-1.035,1.538-2.31.866-3.487-.571-1.008-1.953-.384-2.968-.44a25.181,25.181,0,0,0-4.654.017c-2.563.339-3.141-.765-2.968-3.093.107-1.472.665-1.763,1.946-1.714,2.036.073,4.076.017,6.116.017"
          transform="translate(55.424 5.223)"
          fill="#fff"
        />
        <path
          id="Tracé_16569"
          data-name="Tracé 16569"
          d="M157.876,295.495a21.433,21.433,0,0,1,13.2-2.712c2.265,1.437,4.99,1.967,7.072,3.827,2.906,2.6,2.978,2.518.326,5.163-1.229,1.229-2.521,2.4-3.688,3.692-.952,1.053-1.479,1.288-2.573-.052a6.079,6.079,0,0,0-7.567-1.693,6.468,6.468,0,0,0-.184,11.788q-3.652,3.714-7.3,7.428c-8.166-5.711-9.08-18.018-1.856-25.107.828-.81,1.714-1.555,2.577-2.334"
          transform="translate(0 10.356)"
          fill="#fabc11"
        />
        <path
          id="Tracé_16570"
          data-name="Tracé 16570"
          d="M218.565,308.354c.024,3.339,2.043,7.044,6.421,9.077a11.569,11.569,0,0,0,12.6-1.513c1.018-.786,1.732-1.784,3.079-.343,1.056,1.132.963,1.777-.107,2.795-4.346,4.128-12.436,5.091-17.98,2.04a15.423,15.423,0,0,1-7.75-16.491,15.137,15.137,0,0,1,13.032-12.346c4.329-.468,8.343.263,11.664,3.293.585.537,1.943,1.06.6,2.237-.914.8-1.517,1.919-3.023.575-3.685-3.286-7.972-3.432-12.232-1.406-4.36,2.078-6.31,5.825-6.3,12.083"
          transform="translate(157.916 7.383)"
          fill="#fff"
        />
        <path
          id="Tracé_16571"
          data-name="Tracé 16571"
          d="M255.172,303.134c0-3.4.069-6.5-.031-9.6-.055-1.628.679-1.891,2.088-1.912,1.545-.028,1.718.738,1.714,1.974-.028,8.343.007,16.685-.042,25.024,0,.9.672,2.313-1.112,2.466-1.468.121-2.971.222-2.715-2.116-5.607,3.8-10.3,3.6-13.787-.4a11.587,11.587,0,0,1-.083-14.971c3.574-4.083,8.343-4.3,13.967-.464M242.4,311.127c.021,4.125,2.694,7.165,6.365,7.224,3.744.062,6.486-3.068,6.466-7.39-.021-4.218-2.6-7.12-6.351-7.134-3.91-.017-6.5,2.9-6.479,7.3"
          transform="translate(216.985 7.825)"
          fill="#fff"
        />
        <path
          id="Tracé_16572"
          data-name="Tracé 16572"
          d="M211.756,305a10.923,10.923,0,1,1-10.819-10.957A10.818,10.818,0,0,1,211.756,305m-10.985-6.524a6.5,6.5,0,0,0,.087,12.98c3.6-.021,6.182-2.843,6.112-6.68a6.14,6.14,0,0,0-6.2-6.3"
          transform="translate(97.292 13.776)"
          fill="#fff"
        />
        <path
          id="Tracé_16573"
          data-name="Tracé 16573"
          d="M205.9,306.686c-.052-7.106,3.034-11.231,7.979-12.36,4.783-1.094,9.111,1.188,11.418,5.849.724,1.455.606,2.2-1,2.805-3.619,1.361-7.189,2.854-10.763,4.336-.665.28-1.725.326-1.7,1.3.021.776.741,1.313,1.378,1.777a5.924,5.924,0,0,0,7.511-.5c2.05-2.1,3.311-.339,4.273.686,1.146,1.216-.613,1.96-1.354,2.577a10.317,10.317,0,0,1-11.664,1.593c-4.076-1.894-6-5.3-6.074-8.059m4.873-2.227c2.781-1.129,5.34-2.157,7.889-3.214.5-.208,1.257-.263,1.281-.963.031-.779-.693-1.091-1.288-1.392a4.907,4.907,0,0,0-5.215.288,5.147,5.147,0,0,0-2.667,5.281"
          transform="translate(136.687 13.82)"
          fill="#fff"
        />
        <path
          id="Tracé_16574"
          data-name="Tracé 16574"
          d="M204.84,305.027a10.924,10.924,0,1,1-10.791-10.985,10.735,10.735,0,0,1,10.791,10.985m-4.751-.048a6.229,6.229,0,0,0-6.019-6.511c-3.657-.09-6.292,2.608-6.306,6.448-.014,3.813,2.625,6.576,6.247,6.535a6.265,6.265,0,0,0,6.078-6.473"
          transform="translate(80.257 13.783)"
          fill="#fff"
        />
        <path
          id="Tracé_16575"
          data-name="Tracé 16575"
          d="M152.358,306.678l7.3-7.428a13.756,13.756,0,0,0,4.263.571c3.941.035,7.879.1,11.816.152l-.069,10.168c-6.227-.152-12.523.97-18.663-.841-1.721-.509-3.484-1.074-4.651-2.622"
          transform="translate(4.798 26.614)"
          fill="#3aa956"
        />
        <path
          id="Tracé_16576"
          data-name="Tracé 16576"
          d="M235.853,294.148A10.245,10.245,0,0,1,246.267,304.7c.014,6.22-4.488,10.9-10.483,10.878-6.043-.014-10.344-4.575-10.317-10.947.024-6.15,4.339-10.5,10.386-10.486m6.57,10.656c0-4.2-2.829-7.231-6.715-7.2a6.8,6.8,0,0,0-6.58,7.082c-.021,4.048,2.964,7.4,6.625,7.439,3.723.042,6.67-3.193,6.67-7.324"
          transform="translate(184.873 14.047)"
          fill="#fff"
        />
        <path
          id="Tracé_16577"
          data-name="Tracé 16577"
          d="M246.513,312.165c-3.183,2.985-6.3,4-9.808,2.414-3.494-1.579-4.322-4.724-4.318-8.221,0-3.3.035-6.6-.021-9.9-.021-1.337.142-2.116,1.842-2.154,1.832-.038,2.05.765,2.016,2.258-.076,3.2-.111,6.407.007,9.6.135,3.785,1.8,5.558,4.928,5.565,2.815,0,5.1-2.22,5.3-5.5s.166-6.6.107-9.891c-.028-1.506.454-2.012,1.988-2.026,1.638-.014,1.925.7,1.912,2.109-.059,5.139.01,10.282-.055,15.425-.014.98.71,2.435-1.264,2.663-1.617.184-2.653-.2-2.629-2.351"
          transform="translate(201.863 14.433)"
          fill="#fff"
        />
        <path
          id="Tracé_16578"
          data-name="Tracé 16578"
          d="M203.892,306.791c0-4.557.1-9.122-.045-13.676-.062-1.957.779-2.209,2.41-2.189,1.555.021,2.49.083,2.466,2.126q-.166,13.678,0,27.355c.021,2.071-.828,2.345-2.583,2.393-1.839.049-2.32-.582-2.282-2.331.1-4.557.035-9.122.035-13.679"
          transform="translate(131.614 6.11)"
          fill="#fff"
        />
        <path
          id="Tracé_16579"
          data-name="Tracé 16579"
          d="M223.422,306.559c0-4.263.024-8.526-.021-12.789-.014-1.323.118-2.178,1.829-2.157,1.572.014,1.967.565,1.957,2.04-.055,8.433-.042,16.862,0,25.291,0,1.33-.132,2.123-1.829,2.164-1.919.049-1.967-.942-1.953-2.341.045-4.069.021-8.138.021-12.207"
          transform="translate(179.781 7.8)"
          fill="#fff"
        />
      </g>
    </SvgIcon>
  );
};

export default IconGoogleCloud;
