import React, { useEffect, useState } from 'react';
import styles from './style';
import { Box } from '@material-ui/core';
import IconAws from '../../Common/Icons/IconAws';
import IconNode from '../../Common/Icons/IconNode';
import IconReactJS from '../../Common/Icons/IconReact';
import IconGoogleCloud from '../../Common/Icons/IconGoogleCloud';
import IconReactNative from '../../Common/Icons/IconReactNative';
import ScrollTo from '../../Common/ScrollTo';
import ContactForm from '../../Common/ContactForm';
import Techonologies from '../../Common/Technologies';
import BlocHeaderTemoignage from '../../Common/BlocHeaderTemoignage';
import BlocGallery from '../../Common/BlocGalleryTemoignage';
import MotClient from '../../Common/BlocMotsClient';
import img1 from '../../../images/mockup_mobile_and_laptop.png';
import gallery1 from '../../../images/eliotech/mockup_laptop_mlti_eliotech1.png';
import gallery2 from '../../../images/eliotech/mockup_laptop_mlti_eliotech2.png';
import gallery3 from '../../../images/eliotech/mockup_mobile_mlti_eliotech3.png';

const TechnologiesItem2 = [
  {
    id: 1,
    icon: <IconAws width="85.223" height="50.96" viewBox="0 0 85.223 50.96" />,
    title: 'IconAws',
  },
  {
    id: 2,
    icon: <IconNode width="177.666" height="65" viewBox="0 0 177.666 50.96" />,
    title: 'IconNode',
  },
  {
    id: 3,
    icon: (
      <IconGoogleCloud
        width="325.599"
        height="50.961"
        viewBox="0 0 325.599 50.961"
      />
    ),
    title: 'IconGoogleCloud',
  },
  {
    id: 4,
    icon: <IconReactJS width="190" height="50.961" viewBox="0 0 190 50.961" />,
    title: 'IconReactJS',
  },
  {
    id: 5,
    icon: <IconReactNative width="260" height="52" viewBox="0 0 260 52" />,
    title: 'IconReactNative',
  },
];

const GalleryItems = [
  {
    id: 1,
    images: gallery1,
  },
  {
    id: 2,
    images: gallery2,
  },
  {
    id: 3,
    images: gallery3,
  },
];
const PageEliotech = () => {
  const classes = styles();
  const [showScrollTo, setShowScrollTo] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    setShowScrollTo(offset > 1000);
  };
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const domaine_ext = url.includes(".fr");

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Box id="elioTeknologyPage" className={classes.cardOfferSection}>
      {showScrollTo ? (
        <ScrollTo element="/awspartner/elioteknology/#elioTeknologyPage" />
      ) : null}

      <BlocHeaderTemoignage
        title="ElioTeknology"
        subTitle="Courte description du projet"
        description="ElioTeknology s'engage à concevoir et à fabriquer des chauffe-eau solaires et à exporter des chauffe-eau solaires sans réservoir et des réservoirs de chauffe-eau solaires dans le monde entier. Pour cela nous avons créer une application mobile comme interface utilisateur et une application web pour l'admnistration de l'application."
        image={img1}
      />
      <BlocGallery
        title="<span>Quelques </span>images"
        description="Découvrez les actus de HaiRun Technology, des astuces et pleins d'autres items autour de la technologie et du digital."
        items={GalleryItems}
      />
      <Techonologies
        bgcolor="#333333"
        title="Les Technologies utilisées <br/> <span>pour la réalisation du projet</span>"
        description="Les Logiciels et application web que nous utilisons dans le projet"
        items={TechnologiesItem2}
        minWidthItem="120px"
        marginTopItem="24px"
        marginBottomItem="24px"
      />
      <MotClient
        title="<span>Un petit mot </span>du client"
        description="Nous avons décidé de confier le développement de notre application à HaiRun Technology. Peu d’entreprises ont cette capacité de répondre à nos besoins en si peu de temps, avec des coûts abordables. Nous avons eu la chance de travailler avec une équipe efficace."
        name="Elioteknology"
        poste="CEO Elioteknology"
      />
      {!domaine_ext ?
        <ContactForm
          title="<span>Nous restons à votre</span> disposition"
          description="Pour toute question, n'hésitez pas à nous contacter."
          mailto="contact@hairun-technology.com"
          formTitle="Contactez-<span>nous</span>"
        />
        :
        <ContactForm
          title="<span>Nous restons à votre</span> disposition"
          description="Pour toute question, n'hésitez pas à nous contacter."
          mailto="contact@hairun-technology.fr"
          formTitle="Contactez-<span>nous</span>"
        />}
    </Box>
  );
};

export default PageEliotech;
